import {Alert, Box, Grid, Input, LinearProgress, Snackbar, SnackbarOrigin,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import './Styles.css';
import {useNavigate} from 'react-router-dom';
import {sendCode, sendEmail} from '../../services/vidcredentials';
import {useTranslation} from 'react-i18next';

interface State extends SnackbarOrigin {
  open: boolean;
}

function EmailForm() {
  const {t} = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [requested, setRequested] = useState<boolean>(false);
  const [errorState, setErrorState] = React.useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {vertical, horizontal, open} = errorState;
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cachedEmail = localStorage.getItem('email');
    if (cachedEmail) {
      setEmail(cachedEmail);
      setRequested(true);
    }
  }, []);


  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmailCode = async () => {
    if (!validateEmail(email)) {
      showError(t('emailProcess.invalidEmail'))
      return;
    }
    if (!buttonDisabled) {
      const response: any = await sendEmail(email);
      if (response.status == 201) {
        setBusy(false);
        setRequested(true);
        setButtonDisabled(true);
        localStorage.setItem('email', email);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 2000);
      } else {
        setBusy(false);
        showError(response.error.details);
      }
    }
  };

  const sendCredentialCode = async () => {
    if (code != '') {
      setBusy(true);
      const response: any = await sendCode(email, code);
      if (response.status == 201) {
        setBusy(false);
        navigate('/email-success');
        localStorage.removeItem('email');
      } else {
        setBusy(false);
        showError(response.error.details);
      }
    }
  };

  const handleEmailCode = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 7) {
      setCode(onlyNums);
    }
  };

  const showError = (message: string) => {
    setErrorMessage(message);
    setErrorState({...errorState, open: true});
  };

  const hideError = () => {
    setErrorState({...errorState, open: false});
  };

  return (
    <Grid container className="mainContainer">
      <Grid item xs md className="titleContainer">
        <text className="topTitle">{t('emailProcess.title')}</text>
      </Grid>
      <Grid container className="midConnectContainerBackgroundEmail">
        {busy && (
          <Box sx={{width: '100%'}}>
            <LinearProgress />
          </Box>
        )}
        <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
          <Grid xs={12} md={12}>
            <text className="stepLabel">
              {t('shared.step').toUpperCase()} {requested ? '02' : '01'}
            </text>
            <br />
            <br />
            <text className="leftSideTitle">{t('emailProcess.titleAlt')}</text>
          </Grid>
          <div className="dividerStep" />
          <Grid xs={12} md={12}>
            {requested ? (
              <Input
                disableUnderline
                sx={{
                  background: '#CFD0DB',
                  borderRadius: '2px',
                  maxWidth: '25rem',
                  width: '100%',
                  p: 1,
                  px: 3,
                  mb: 3,
                }}
                value={code}
                onChange={handleEmailCode}
                placeholder={t('phoneProcess.placeholderCode')}
                type="text"
              />
            ) : (
              <Input
                disableUnderline
                value={email}
                sx={{
                  background: '#CFD0DB',
                  borderRadius: '2px',
                  maxWidth: '25rem',
                  width: '100%',
                  p: 1,
                  px: 3,
                  mb: 3,
                }}
                onChange={e => {
                  setEmail(e.target.value);
                }}
                placeholder={'johndoe@mail.com'}
                type={'email'}
              />
            )}
            <br />
            <text className="leftContent">
              {t(`emailProcess.processDescription${requested ? 2 : 1}`)}
            </text>
          </Grid>
          <Grid container sx={{alignItems: 'center'}} xs={12} md={12}>
            <Grid xs={12} md={8}>
              <Box
                sx={{
                  border: 2,
                  borderRadius: '20px',
                  p: 1.5,
                  px: 3,
                  my: 7,
                  cursor: 'pointer',
                }}
                onClick={requested ? sendCredentialCode : sendEmailCode}
                display={'flex'}
                justifyContent={'space-between'}>
                <text className="buttonLabel">
                  {t(`shared.${requested ? 'validate' : 'sendCode'}`)}
                </text>
                <img
                  className="buttonGo"
                  src={require('../../assets/images/arrow-right.png')}
                />
              </Box>
            </Grid>
            <Grid sx={{textAlign: 'center'}} xs={12} md={4}>
              {requested && (
                <text
                  onClick={sendEmailCode}
                  style={{
                    cursor: 'pointer',
                    color: buttonDisabled ? 'lightgrey' : 'black',
                  }}>
                  {t('shared.sendNewCode')}
                </text>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <img
            className="emailIssueImage"
            src={require('../../assets/images/emailIssue.png')}
          />
        </Grid>
      </Grid>
      <Grid container className="bottomContainer">
        {' '}
        <Snackbar
          key={vertical + horizontal}
          anchorOrigin={{vertical, horizontal}}
          open={open}
          autoHideDuration={3000}
          onClose={hideError}>
          <Alert onClose={hideError} severity="error" sx={{width: '100%'}}>
            <text style={{fontSize: 18, fontWeight: 'bold'}}>
              {errorMessage}
            </text>
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default EmailForm;
