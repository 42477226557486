import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PhoneForm from '../../components/IssuePhone/PhoneForm';
import HeaderWrapper from '../../providers/HeaderWrapper';

function PhoneProcess() {
  const currentCode = localStorage.getItem('code');
  const navigate = useNavigate();
  useEffect(() => {
    if (!currentCode) {
      navigate('/issue-credential/connect-wallet');
    }
    document.body.scrollIntoView({behavior: 'smooth'});
  }, []);

  return (
    <HeaderWrapper
      handleClick={(refNum: number) =>
        navigate('/', {state: {section: refNum}})
      }>
      <PhoneForm />
    </HeaderWrapper>
  );
}

export default PhoneProcess;
