import {
    Alert,
    Box,
    Grid,
    LinearProgress,
    Snackbar,
    SnackbarOrigin,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import './Styles.css';
import {useNavigate} from 'react-router-dom';
import {
    getResultsStatus,
    sendVideoLinkRequest, sendIssueKycCredentialRequest,
} from '../../services/vidcredentials';
import {useTranslation} from "react-i18next";

interface State extends SnackbarOrigin {
    open: boolean;
}

function KycForm() {
    const {t} = useTranslation();
    const [processOk, setProcessOk] = useState(false);
    const [processKo, setProcessKo] = useState(false);
    const [checkingProcess, setCheckingProcess] = useState<boolean>(false);
    const [requested, setRequested] = useState<boolean>(false);
    const [errorState, setState] = React.useState<State>({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });
    const [busy, setBusy] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {vertical, horizontal, open} = errorState;
    const [disabled, setDisabled] = useState<boolean>(false);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const navigate = useNavigate();
    const [kycUrl, setKycUrl] = useState<string>('');
    const [kycInProgress, setKycInProgress] = useState<boolean>(false);

    const checkResultsStatus = async(): Promise<string> => {
        const requestId = localStorage.getItem('requestId');
        if(!requestId){
            return 'failed';
        }
        const response: any = await getResultsStatus(requestId);
        if(!response || response.status !== 200 || !response.data) {
            return 'failed';
        }
        if(response.data.available !== true){
            return  'waiting'
        }
        return 'resolved';
    }

    useEffect(() => {
        const url = window.location.href;
        const handleVerification = async () => {
            if (url.includes("kyc-process-ok")) {

                setBusy(true);
                setRequested(true)
                setCheckingProcess(true);
                setProcessKo(false);
                setProcessOk(false);

                const checkResultInterval = setInterval(async()=>{
                    const resultsStatus = await checkResultsStatus();
                    if (resultsStatus === 'failed'){
                        navigate("/kyc-process-ko");
                        setProcessKo(true)
                        setBusy(false);
                        setCheckingProcess(false)
                        clearInterval(checkResultInterval);
                        clearTimeout(maxTimeReachedTimeout);
                        return;
                    }
                    if (resultsStatus === 'resolved'){
                        setProcessOk(true);
                        setProcessKo(false);
                        setBusy(false);
                        setCheckingProcess(false);
                        clearInterval(checkResultInterval);
                        clearTimeout(maxTimeReachedTimeout);
                        return;
                    }
                }, 3000);

                const maxTimeReachedTimeout = setTimeout(() => {
                    clearInterval(checkResultInterval);
                    navigate('/kyc-process-ko');
                    setProcessKo(true);
                    setBusy(false);
                    setCheckingProcess(false);
                }, 30000);

            } else if (url.includes("kyc-process-ko")) {
                setProcessKo(true)
                setRequested(true)
                setCheckingProcess(false);
            }
        };
        handleVerification();

        const messageEvent = 'message';

        const eventListener = (e: MessageEvent) => {
            if (e.data && e.data.code) {
                try {
                    if (e.data.code === 0) {
                        setKycInProgress(false);
                        navigate("/kyc-process-ko");
                    } else if (e.data.code === 1) {
                        setKycInProgress(false);
                        navigate("/kyc-process-ok");
                    }
                } catch (error) {
                    setKycInProgress(false);
                    navigate("/kyc-process-ko");
                }
            }
        };

        window.addEventListener(messageEvent, eventListener);

        return () => {
            window.removeEventListener(messageEvent, eventListener);
        };

    },[navigate])

    const getVideoLinkInformation = async () => {

        if (!buttonDisabled) {
            const screenType =  window.innerWidth > 769 ? 'desktop' : 'mobile';
            const response: any = await sendVideoLinkRequest(screenType);
             if (response.status === 200){
                 localStorage.setItem('videoIdentifier', response.data.videoIdentifier);
                 localStorage.setItem('requestId',response.data.requestId);
                 setKycUrl(response.data.link);
                 setKycInProgress(true)
            }
            if (response.status == 201) {
                setBusy(false);
                setRequested(true);
                setButtonDisabled(true);

                setTimeout(() => {
                    setButtonDisabled(false);
                }, 2000);
                return;
            }
            if (response.error) {
                setBusy(false);
                showError(response.error.message ?? response.error.details);
                return;
            }
        }
    };

    const issueKycCredential = async () => {

            setBusy(true);

            const response: any = await sendIssueKycCredentialRequest();

            if (response.status == 201) {
                setBusy(false);
                localStorage.setItem('code', '');
                localStorage.removeItem('kyc');
                localStorage.removeItem('videoIdentifier');
                localStorage.removeItem('requestId');
                navigate("/kyc-success");
            } else {

                setBusy(false);
                showError(response.error.details);
            }

    };

    const showError = (message: string) => {
        setErrorMessage(message);
        setState({...errorState, open: true});
    };

    const hideError = () => {
        setState({...errorState, open: false});
    };

    return (
        <Grid container className="mainContainer">
            <Grid item xs md className="titleContainer">
                <text className="topTitlePhone">{t('kycProcess.title')}</text>
            </Grid>
            <Grid container
                  className={`midConnectContainerBackgroundEmail ${kycInProgress ? 'kyc-process-iframe' : ''}`}>
                {busy && (
                    <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>
                )}

                {kycInProgress && (<text className="kyc-process-title">{t('kycProcess.iframeText.title')}</text> )}

                {kycInProgress && (<div className="kyc-process-iframe-mask">
                    <iframe id="neocheck-iframe" style={{width: '100%', border: 'none'}} src={kycUrl}
                            allow="microphone; camera"/>
                </div>)}

                {kycInProgress && (<text className="kyc-process-subtitle">{t('kycProcess.iframeText.subtitle')}</text> )}

                {kycInProgress && (<text className="kyc-process-message">{t('kycProcess.iframeText.message')}</text> )}

                {!kycInProgress ? <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
                    <Grid xs={12} md={12}>
                        {!requested &&
                            <text className="stepLabel">{t('shared.step').toUpperCase()} 01</text>
                        }
                        <br/>
                        <br/>
                        <text className="leftSideTitle">{
                            !requested ?
                                t('kycProcess.titleAlt')
                                :
                                processOk ? t('kycProcess.titleAltOK')
                                    :
                                    checkingProcess ? t('kycProcess.titleAltProcessing')
                                        : t('kycProcess.titleAltKO')
                        }</text>
                    </Grid>
                    <div className="dividerStep"/>
                    <Grid xs={12} md={12}>

                        {
                            requested && processOk && (<text className="leftContent">
                                <strong>{t('kycProcess.results.success.messageBold')}</strong><br/>
                                <br/>
                                {t('kycProcess.results.success.message')}
                            </text>)
                        }
                        {
                            requested && processKo && (<text className="leftContent">
                                <strong>{t('kycProcess.results.fail.messageBold')}</strong><br/>
                                <br/>
                                {t('kycProcess.results.fail.message')}
                            </text>)
                        }
                        {
                            requested && checkingProcess && (<text className="leftContent">
                                <strong>{t('kycProcess.results.processing.messageBold')}</strong><br/>
                                <br/>
                                {t('kycProcess.results.processing.message')}
                            </text>)
                        }
                        <br/>
                        <text className="leftContent">
                            {requested
                                ? ''
                                : t('kycProcess.step1')}
                        </text>
                    </Grid>
                    <Grid container sx={{alignItems: 'center'}} xs={12} md={12}>

                        <Grid xs={12} md={8}>

                            {!disabled && !checkingProcess &&
                                <Box
                                    sx={{
                                        border: 2,
                                        borderRadius: '20px',
                                        p: 1.5,
                                        px: 3,
                                        my: 7,
                                        cursor: 'pointer',
                                    }}
                                    onClick={requested ? processOk ? issueKycCredential : getVideoLinkInformation : getVideoLinkInformation}
                                    display={'flex'}
                                    justifyContent={'space-between'}>
                                    <text className="buttonLabel">
                                        {requested ? processOk ? t('kycProcess.button.getVerification') : t('kycProcess.button.retryProcess') : t('kycProcess.button.startProcess')}
                                    </text>
                                    <img
                                        className="buttonGo"
                                        src={require('../../assets/images/arrow-right.png')}
                                    />
                                </Box>
                            }
                        </Grid>


                    </Grid>
                </Grid> : <></>
                }
                {
                    !kycInProgress ? <Grid xs={12} md={6}>
                        <img
                            className="emailIssueImage"
                            src={require('../../assets/images/kycIssue.png')}
                        />
                    </Grid> : <></>
                }

            </Grid>
            <Grid container className="bottomContainer">
                {' '}
                <Snackbar
                    key={vertical + horizontal}
                    anchorOrigin={{vertical, horizontal}}
                    open={open}
                    autoHideDuration={3000}
                    onClose={hideError}>
                    <Alert onClose={hideError} severity="error" sx={{width: '100%'}}>
                        <text style={{fontSize: 18, fontWeight: 'bold'}}>
                            {errorMessage}
                        </text>
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}

export default KycForm;
