export default {
  shared: {
    getStarted: 'Get started',
    step: 'Step',
    connect: 'Connect',
    validate: 'Validate',
    sendCode: 'Send code',
    sendNewCode: 'Send new code',
  },
  home: {
    getCredentials: 'Get your verifiable credentials on your wallet',
    sections: {
      what: 'What is it',
      issue: 'Issue credentials',
      other: 'Other services',
      contact: 'Contact',
    },
    firstSection: {
      title: 'What is VIDissuer',
      issuerDefinitionBold:
        'VIDissuer is a reliable service provided by Validated ID',
      issuerDefinition:
        'to issue verifiable credentials directly to your identity wallet for safekeeping.',
      secondParagraph:
        "Validated ID's user-friendly identity wallet, VIDwallet, available on iOS and Android, implements a digital counterpart of a physical wallet that allows users to store and manage their digital credentials.",
      thirdParagraph:
        'Each digital credential, also known as verifiable credential, is similar to a card that you can easily share from your identity wallet. These cards may include a range of identity information, such as verification of your phone number, email address, identity, or social media accounts.',
    },
    secondSection: {
      title: 'Issuing credentials has never been easier',
      subtitle:
        "To create a verifiable credential, just choose the one you need from the options provided below. Then click the link and follow the instructions accordingly. Here's a list of available options for creating and issuing verifiable credentials:",
      comingSoon: 'Coming Soon',
    },
    credentialTypes: {
      phone: {
        title: 'Mobile phone number',
        description:
          'Verify your contact number by requesting a verification code to be sent to your device and turn it into a verified credential on your VIDwallet.',
      },
      email: {
        title: 'Email',
        description:
          'Verify your email by requesting a verification code to be sent to your inbox and store it as a verified credential on your VIDwallet.',
      },
      id: {
        title: 'ID card or passport',
        description:
          'Verify your ID in seconds through trusted KYC processes and store ID-related attributes as credentials on your VIDwallet.',
      },
      socialNetworks: {
        title: 'Social Networks',
        description:
          'Our SSI-based solutions help you choose which social media platforms can access your personal information and provide you the control to retrieve it anytime.',
      },
      certBridge: {
        title: 'CertBridge',
        description:
          'Seal verifiable credentials using qualified certificates and make SSI solutions scalable and interoperable. We offer more security for citizens by enhancing the legal certainty of any verifiable credential.',
      },
    },
    otherServices: {
      vidconnect: {
        title: 'VIDconnect',
        description:
          'Implement identity protocols, namely the OpenID Connect for verifiable credentials family, and connects VIDchain users with others and services.',
      },
      vidcredential: {
        title: 'VIDcredential',
        description:
          'Provide exceptional Digital Identity and Credential Management protection for all identities across all devices with VIDcredentials Studio.',
      },
      vidverifier: {
        title: 'VIDverifier',
        description:
          'Verify the authenticity and validity of credentials by simply performing an authentication flow toward VIDverifier.',
      },
      vidwallet: {
        title: 'VIDwallet',
        description:
          'Regain control of your digital identity and safely store your verifiable credentials, and share them easily with others when needed.',
      },
    },
  },
  connectWallet: {
    title: 'Connect wallet',
    titleAlt: 'Connect your wallet',
    description:
      'If you are on this screen, your wallet may not yet be connect, so that we can recognise your ID and you can download credentials more quickly and securely.',
    instructions:
      "It's very easy, just open the camera on your device and scan the QR code.",
  },
  phoneProcess: {
    title: 'Phone number credential',
    titleAlt: 'Phone number',
    getBadge: 'Get your badge in two easy steps',
    description:
      'Verify your phone number by requesting a verification code to be sent to your device and store it as a verified credential on your VIDwallet.',
    step1: 'Enter your phone number.',
    step2: 'You will receive a verification code in your phone.',
    placeholderPhone: 'Type your phone number',
    placeholderCode: 'Type your verification code',
    processDescription1: 'You will receive a verification code in your mobile',
    processDescription2:
      'We have sent you a SMS. Please, check your message inbox.',
  },
  emailProcess: {
    title: 'Email credential',
    titleAlt: 'Email verification',
    description:
      'Verify your email by requesting a verification code to be sent to your inbox and store it as a verified credential on your VIDwallet.',
    step1: 'Enter your email address.',
    step2: 'You will receive a verification code in your email.',
    processDescription1: 'You will receive a verification code in your email',
    processDescription2:
      'We have sent you an email. Please, check your message inbox.',
    invalidEmail: 'Please enter a valid email address.'
  },
  kycProcess:{
    title: 'ID card credential',
    titleAlt: 'Identifier number',
    getBadge: 'Get your badge in two easy steps',
    titleAltOK: 'Well done',
    titleAltProcessing: 'Checking process...',
    titleAltKO: 'The process has failed!',
    description:'Verify your ID in seconds through trusted KYC processes and store ID-related attributes as credentials on your VIDwallet.',
    step1:'You will be redirected to our validation screen',
    step2:'Obtain your credential',
    processDescription1:'',
    processDescription2:'',
    results:{
      success:{
        subtitle: 'Well done!',
        messageBold: 'The process has been successfully completed!',
        message: 'Your credential has been generated.',
      },
      processing:{
        subtitle: 'Checking process...',
        messageBold: 'The document data is being processed.',
        message: 'It can take some seconds,  please wait.',
      },
      fail:{
        subtitle: 'The process has failed!',
        messageBold: "The data could not be processed. Please ensure your document is valid and the images are clear and legible. Try again when you're ready.",
        message: "Please, try again."
      }
    },
    button:{
      startProcess: 'Start ID validation',
      retryProcess: 'Retry process',
      getVerification: 'Get credential'
    },
    iframeText:{
      title:'This QR code has to be scanned from the Camera of your phone, NOT from the Wallet.',
      subtitle:'You must continue the process on your mobile phone',
      message:'Do not close this window. Process will resume when validation finishes.'
    }
  },
  kycInvitationCode:{
    title: 'Invitation Code',
    description: "This credential requires an invitation code, if you don't have one, please contact Validated ID.",
    button:{
      continue: 'Continue',
      retry: 'Try again',
    },
    incorrectMessage: 'Incorrect code, try again.'
  },
  success: {
    subtitle: 'Well done!',
    messageBold: 'The process has been successfully completed!',
    message:
      'Go to the Wallet and accept the credential.',
  },
};
