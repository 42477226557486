import React from 'react';
import {Box, Grid, Link} from '@mui/material';
import './Styles.css';
import {useTranslation} from 'react-i18next';

function InitialTopTitle() {
  const {t, i18n} = useTranslation();

  return (
    <Box sx={{pt: 4}}>
      <Grid container className="titleContainer">
        <Grid xs={12} md={7} className="innerInitialGrid">
          <div className="initialTextContainer">
            <text className="initialTitle">{t('home.getCredentials')}</text>
          </div>
          <div className="logoPlatformContainer">
            <Link
              href="https://play.google.com/store/apps/details?id=com.validatedid.wallet"
              color={'inherit'}
              target={'_blank'}
              underline="none">
              <img
                className={
                  i18n.language === 'en' ? 'googlePlayEnLogo' : 'googlePlayLogo'
                }
                height={i18n.language === 'en' ? 80 : 70}
                src={require(
                  `../assets/images/PlayStore/google-play-badge-${i18n.language}.png`,
                )}
              />
            </Link>
            <Link
              href="https://apps.apple.com/es/app/vidwallet/id1554340592"
              target={'_blank'}
              color={'inherit'}
              underline="none">
              <img
                className="appStoreLogo"
                height={55}
                src={require(
                  `../assets/images/AppStore/app-store-badge-${i18n.language.replace(
                    'ca',
                    'es',
                  )}.svg`,
                )}
              />
            </Link>
          </div>
        </Grid>
        <Grid xs={12} md={5} className="innerInitialGrid">
          <img
            className="phoneImageStyle"
            src={require('../assets/images/phone.png')}></img>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InitialTopTitle;
