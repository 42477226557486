import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import InitialTopTitle from '../components/InitialTopTitle';
import WhatIsItSection from '../components/WhatIsItSection';
import IssueCredential from '../components/IssueCredential';
import OtherServices from '../components/OtherServices';
import {validateToken} from '../utils/utils';
import Footer from '../components/Footer/Footer';
import HeaderWrapper from '../providers/HeaderWrapper';

function HomePage() {
  const titleRef = useRef(null);
  const whatIsRef = useRef(null);
  const issueCredentialRef = useRef(null);
  const otherServicesRef = useRef(null);

  const sectionRefs: React.MutableRefObject<HTMLDivElement | null>[] = [
    titleRef,
    whatIsRef,
    issueCredentialRef,
    otherServicesRef,
  ];

  let {state} = useLocation();

  useEffect(() => {
    validateToken();
  }, []);

  useEffect(() => {
    if (state?.section) {
      handleCLick(state.section);
    }
  }, [state?.section]);

  const handleCLick = (refNum: number) => {
    sectionRefs[refNum].current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <HeaderWrapper handleClick={handleCLick}>
      <div ref={titleRef}>
        <InitialTopTitle />
      </div>
      <div ref={whatIsRef}>
        <WhatIsItSection />
      </div>
      <div ref={issueCredentialRef}>
        <IssueCredential />
      </div>
      <div ref={otherServicesRef}>
        <OtherServices />
      </div>
      <div>
        <Footer />
      </div>
    </HeaderWrapper>
  );
}

export default HomePage;
