import React, {useEffect} from 'react';
import EmailCredential from '../components/IssueEmail/EmailCredential';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ConnectWallet from '../components/ConnectWallet/ConnectWallet';
import PhoneCredential from '../components/IssuePhone/PhoneCredential';
import {validateToken} from '../utils/utils';
import KycCredential from "../components/IssueKyc/KycCredential";
import HeaderWrapper from '../providers/HeaderWrapper';

function IssueCredential() {
  const {type} = useParams();
  const {search} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    validateToken();
    const redirectUrl = new URLSearchParams(search).get('redirect_url');
    redirectUrl && localStorage.setItem('redirect_url', redirectUrl);
  }, []);

  useEffect(() => {
    document.body.scrollIntoView({behavior: 'smooth'});
  }, []);

  const renderComponent = (route: string | undefined) => {
    switch (route) {
      case 'email':
        return <EmailCredential />;
      case 'phone':
        return <PhoneCredential />;
      case 'cert':
        return <EmailCredential />;
      case 'connect-wallet':
        return <ConnectWallet />;
      case 'kyc':
        return <KycCredential />;
      default:
        return <EmailCredential />;
    }
  };

  return (
    <HeaderWrapper
      handleClick={(refNum: number) =>
        navigate('/', {state: {section: refNum}})
      }>
      {renderComponent(type)}
    </HeaderWrapper>
  );
}

export default IssueCredential;
