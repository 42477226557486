import {Grid} from '@mui/material';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="rightSide">
        <img
          className="logoFooter"
          src={require('../../assets/images/logoOnly.png')}
          alt="City"
        />
        <text className="textFooter">
          Copyright © {currentYear} Validated ID, SL
        </text>
      </div>
    </div>
  );
}

export default Footer;
