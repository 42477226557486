import axios from 'axios';
import {BACKEND, OPENID} from '../config';

export interface BackendError {
  statusCode: number;
  internalCode: string;
  message: string;
  timestamp: string;
}

export interface ParsedBackendError {
  title: string;
  details?: string;
}

export interface BackendResponse {
  data?: unknown;
  error?: ParsedBackendError;
}

function translateBackendError(error: BackendError): ParsedBackendError {
  console.log(error, error.message);
  const parsedError = {
    title: 'Internal error',
    details: error.message.split(']')[1],
  };
  return parsedError;
}

const SUPPORTED_LANGUAGES = ['en', 'es'];

async function authLogin(code: string) {
  try {
    const body = {
      code: code,
      redirectUri: OPENID.REDIRECT_CALLBACK_LOGIN,
    };

    const url = `${BACKEND.ENDPOINTS.AUTHORIZE}`;

    const response = await axios.post(url, body);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error de login'} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

function logOut() {
  // TODO: Clear local storage?
  localStorage.setItem('access_token', '');
  localStorage.setItem('did', '');
  localStorage.setItem('code', '');
  localStorage.removeItem('email');
  localStorage.removeItem('phone');
}

async function sendEmail(email: string) {
  try {
    const accessToken = localStorage.getItem('access_token');
    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0].substring(0, 2)
        : navigator.language.substring(0, 2);
    const body = {
      language: SUPPORTED_LANGUAGES.find(lang => lang === userLocale) ?? 'en',
      email,
    };
    const url = `${BACKEND.BASE_URL}/email/codes`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar email'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

async function sendCode(email: string, code: string) {
  try {
    const accessToken = localStorage.getItem('access_token');
    const did = localStorage.getItem('did');
    const body = {
      email,
      code,
      did,
    };
    const url = `${BACKEND.BASE_URL}/email`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar codigo'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

async function sendPhoneCodeRequest(phoneNumber: string, code: string) {
  try {
    const accessToken = localStorage.getItem('access_token');
    const body = {
      phoneNumber,
      code,
    };
    const url = `${BACKEND.BASE_URL}/phone`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar codigo'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}


async function sendPhoneNumber(phoneNumber: any) {
  try {
    const accessToken = localStorage.getItem('access_token');
    const userLocale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    const body = {
      language: SUPPORTED_LANGUAGES.find(lang => lang === userLocale) ?? 'en',
      phoneNumber,
    };
    const url = `${BACKEND.BASE_URL}/phone/codes`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar email'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}




async function sendVideoLinkRequest(screenType: string) {
  try {
    const accessToken = localStorage.getItem('access_token');

    const url = `${BACKEND.BASE_URL}/kyc/identifier/${screenType}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(url, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar identificador'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
          error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}


async function sendIssueKycCredentialRequest() {
  try {
    const accessToken = localStorage.getItem('access_token');
    const requestId = localStorage.getItem('requestId');

    const url = `${BACKEND.BASE_URL}/kyc`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, {requestId: requestId}, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar identificador'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
          error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

async function sendInvitationCodeRequest(invitationCode: string) {
  try{
    const accessToken = localStorage.getItem('access_token');
    const body = {invitationCode: invitationCode};
    const url = `${BACKEND.BASE_URL}/kyc/invitation`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error al enviar invitation code'} ${response.data}.`);
    }
    return response;
  }catch(error){
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
          error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

async function verifyVideoIdentification(videoIdentifier: string) {
  try{
    const accessToken = localStorage.getItem('access_token');
    const body = { videoIdentifier: videoIdentifier };
    const url = `${BACKEND.BASE_URL}/kyc/verify/video`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(url, body, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error gettings verification status'} ${response.data}.`);
    }
    return response;
  }catch(error){
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
          error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

async function getResultsStatus(requestId: string) {
  try {
    const accessToken = localStorage.getItem('access_token');

    const url = `${BACKEND.BASE_URL}/kyc/status/${requestId}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.get(url, headers);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${'error getting results status'} ${response.data}.`);
    }
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
          error.response?.data as BackendError,
      );
      return {error: parsedError};
    }
    return {error: {title: 'Unknown'}};
  }
}

export {
  authLogin,
  sendEmail,
  sendCode,
  logOut,
  sendPhoneCodeRequest,
  sendPhoneNumber,
  sendIssueKycCredentialRequest,
  sendVideoLinkRequest,
  sendInvitationCodeRequest,
  verifyVideoIdentification,
  getResultsStatus,
};
