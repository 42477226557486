import React, {useEffect} from 'react';
import {authLogin} from '../services/vidcredentials';
import {useNavigate} from 'react-router-dom';
import {decodeToken} from '../utils/utils';

function Callback() {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get('code');
  useEffect(() => {
    if (!code) {
      console.log('Error in the login process');
      return;
    }
    const currentCode = localStorage.getItem('code');
    if (currentCode != code) {
      const login = async () => {
        try {
          const sessionToken = await authLogin(code);
          if ('error' in sessionToken) {
            return;
          }
          const decodedToken: any = await decodeToken(
            sessionToken.access_token,
          );
          localStorage.setItem('access_token', sessionToken.access_token);
          localStorage.setItem('did', decodedToken.userInfo.did);
          localStorage.setItem('code', code);
          const currentpath = localStorage.getItem('path');
          if (currentpath && currentpath != '') {
            const path = currentpath.split('|')[0];
            const component = currentpath.split('|')[1];
            navigate(path, {state: {component: component}});
            localStorage.setItem('path', '');
          } else {
            navigate('/');
          }
        } catch (error) {
          console.log(error);
          console.log('Error unable to login');
          return;
        }
      };
      login();
    } else {
      navigate('/');
    }
  }, [code]);
  return <></>;
}

export default Callback;
