import {useTranslation} from 'react-i18next';
import {supportedLanguages} from '../../i18n/i18n';
import './LanguageSelector.css';
import {useState} from 'react';

function LanguageSelector() {
  const {i18n} = useTranslation();
  const [isDropped, setIsDropped] = useState(false);

  return (
    <div className="mobile-container">
      <div className="language-selector">
        <input
          type="button"
          value={supportedLanguages[i18n.language]}
          onChange={e => i18n.changeLanguage(e.target.value)}
          onClick={() => setIsDropped(!isDropped)}
        />
      </div>
      {isDropped && (
        <div className="options-container">
          {Object.entries(supportedLanguages).map(([langKey, nativeName]) => (
            <button
              key={langKey}
              className={i18n.language === langKey ? 'selected' : ''}
              value={langKey}
              onClick={async e => {
                await i18n.changeLanguage(e.currentTarget.value);
                setIsDropped(false);
              }}>
              {nativeName}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
