import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container} from '@mui/material';
import Header from '../../components/Header';
import KycForm from '../../components/IssueKyc/KycForm';
import KycInvitationForm from '../../components/IssueKyc/KycInvitationForm';
import HeaderWrapper from "../../providers/HeaderWrapper";
import PhoneForm from "../../components/IssuePhone/PhoneForm";

function KYCProcess() {
  const currentCode = localStorage.getItem('code');
  const [isInvitationCodeValid, setIsInvitationCodeValid] = useState<boolean>(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!currentCode) {
      navigate('/issue-credential/connect-wallet');
    }

    document.body.scrollIntoView({behavior: 'smooth'});
  }, []);

  const handleInvitationCodeValidation = (isValid: boolean) => {
    if(!isValid){
      setIsInvitationCodeValid(false);
      return;
    }
    setIsInvitationCodeValid(true);
  };

  return (
      <HeaderWrapper
          handleClick={(refNum: number) =>
              navigate('/', {state: {section: refNum}})
          }>
        {!isInvitationCodeValid ? (
            <KycInvitationForm onValidate={handleInvitationCodeValidation} />
        ) : (
            <KycForm />
        )}
      </HeaderWrapper>
  );
}

export default KYCProcess;
