import React from 'react';
import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import HomePage from './screens/HomePage';
import IssueCredential from './screens/IssueCredential';
import Callback from './screens/Callback';
import PhoneProcess from './screens/phoneFlow/PhoneProcess';
import EmailProcess from './screens/emailFlow/EmailProcess';
import SuccessPage from './screens/SuccessPage/SuccessPage';
import KYCProcess from './screens/KYCFlow/KYCProcess';
import {useTranslation} from 'react-i18next';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/issue-credential/:type" element={<IssueCredential />} />
        <Route path="/kyc-success" element={<SuccessPage type="KYC" />} />
        <Route path="/kyc-process" element={<KYCProcess />} />
        <Route path="/kyc-process-ok" element={<KYCProcess />} />
        <Route path="/kyc-process-ko" element={<KYCProcess />} />
        <Route path="/email-process" element={<EmailProcess />} />
        <Route path="/email-success" element={<SuccessPage type="Email" />} />
        <Route path="/phone-process" element={<PhoneProcess />} />
        <Route path="/phone-success" element={<SuccessPage type="Phone" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
