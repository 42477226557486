import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import '../IssueEmail/Styles.css';
import {useNavigate} from 'react-router-dom';
import {clearInterval} from 'node:timers';
import {useTranslation} from 'react-i18next';

interface SuccessProps {
  type: string;
}

function Success({type}: SuccessProps) {
  const {t} = useTranslation();
  const redirectUrl = localStorage.getItem('redirect_url');
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    if (redirectUrl) {
      let interval = 1;
      setInterval(t => {
        setCounter(counter - interval);
        interval++;
      }, 1000);
      setTimeout(() => {
        localStorage.removeItem('redirect_url');
        window.open(redirectUrl, '_self');
      }, 5500);
    }
  }, []);
  return (
    <Grid container className="mainContainer">
      <Grid item xs md className="titleContainer">
        <text className="topTitle">
          {t(`${type.toLowerCase()}Process.title`)}
        </text>
      </Grid>
      <Grid container className="midConnectContainerBackgroundEmail">
        <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
          <Grid xs={12} md={12}>
            <text className="leftSideTitle">{t('success.subtitle')}</text>
          </Grid>
          <div className="dividerStep" />
          <Grid xs={12} md={12} sx={{pt: 6}}>
            <text className="leftContent">
              <b>{t('success.messageBold')}</b>
              <br />
              {t('success.message')}
            </text>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <img
              className="emailIssueImage"
              src={require(`../../assets/images/${type.toLowerCase()}IssueSuccess.png`)}
          />
        </Grid>
        {redirectUrl ? <text>You will be redirected in {counter}</text> : null}
      </Grid>
      <Grid container className="bottomContainer"></Grid>
    </Grid>
  );
}

export default Success;
