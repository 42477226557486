import {Alert, Box, Container, Divider, Grid} from '@mui/material';
import React, {useEffect} from 'react';
import './Styles.css';
import {OpenIDClient} from '../../libs/client';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function ConnectWallet() {
  const {t} = useTranslation();
  useEffect(() => {
    document.body.scrollIntoView({behavior: 'smooth'});
  }, []);

  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getProvider();
    client.wipeTokens();
    await client.callback();
    await client.getToken({
      scopes: {
        request: ['openid'],
      },
    });
  };

  return (
    <Grid container className="mainConnectContainer">
      <Grid item xs md className="titleContainer">
        <text className="topTitle">{t('connectWallet.title')}</text>
      </Grid>
      <Grid container className="midConnectContainerBackground">
        <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
          <Grid xs={12} md={12}>
            <text className="leftSideTitle">{t('connectWallet.titleAlt')}</text>
          </Grid>
          <div className="divider" />
          <Grid xs={12} md={12}>
            <text className="leftContent">
              {t('connectWallet.description')}
              <br />
              <br />
              <br />
              {t('connectWallet.instructions')}
            </text>
          </Grid>
          <Box
            onClick={signInWithVIDchain}
            sx={{
              border: 2,
              borderRadius: '20px',
              maxWidth: '13rem',
              p: 1.5,
              px: 3,
              my: 7,
              cursor: 'pointer',
            }}
            display={'flex'}
            justifyContent={'space-between'}>
            <text className="buttonLabel">{t('shared.connect')}</text>
            <img
              className="buttonGo"
              src={require('../../assets/images/arrow-right.png')}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <img
            className="connectImage"
            src={require('../../assets/images/connectWallet.png')}
          />
        </Grid>
      </Grid>
      <Grid container className="bottomContainer"></Grid>
    </Grid>
  );
}

export default ConnectWallet;
