import jwtDocode from 'jwt-decode';

export const decodeToken = (token: string) => {
  const decodedToken = jwtDocode(token);
  return decodedToken;
};

export const validateToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (accessToken) {
    const decodedToken: any = decodeToken(accessToken);
    const currentTime = Date.now() / 1000;
    const isExpired = currentTime > decodedToken.exp;
    if (isExpired) {
      // TODO: clean localStorage
      localStorage.setItem('access_token', '');
      localStorage.setItem('did', '');
      localStorage.setItem('code', '');
    }
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
  }
};
