import {
  Alert,
  Box,
  Grid,
  Input,
  InputAdornment,
  LinearProgress,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import './Styles.css';
import {useNavigate} from 'react-router-dom';
import {
  sendPhoneCodeRequest,
  sendEmail,
  sendPhoneNumber,
} from '../../services/vidcredentials';
import {useTranslation} from 'react-i18next';

interface State extends SnackbarOrigin {
  open: boolean;
}

function EmailForm() {
  const {t} = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [requested, setRequested] = useState<boolean>(false);
  const [errorState, setState] = React.useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const [busy, setBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {vertical, horizontal, open} = errorState;
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cachedFullPhoneNumber = localStorage.getItem('phone');
    if (cachedFullPhoneNumber) {
      const [cachedCountryCode, cachedPhoneNumber] =
        cachedFullPhoneNumber.split(' ');
      setRequested(true);
      setCountryCode(cachedCountryCode);
      setPhoneNumber(cachedPhoneNumber);
    }
  }, []);
  const processWord = (formattedNumber: string) => {
    const trimSpecial = formattedNumber.replace(/[^0-9 ]/g, '');
    return '+' + trimSpecial.replace(/\s/g, '');
  };

  const sendPhoneCode = async () => {
    if (
      !phoneNumber ||
      !countryCode ||
      phoneNumber.length < 9 ||
      countryCode.length < 2
    ) {
      const countryCodeMessage =
        !countryCode || countryCode.length < 2 ? 'Country Code' : '';
      const phoneNumberMessage =
        !phoneNumber || phoneNumber.length < 9 ? 'Phone Number' : '';
      const conjunction =
        phoneNumberMessage.length && countryCodeMessage.length ? ' and ' : '';
      showError(
        'Invalid ' +
          countryCodeMessage +
          conjunction +
          phoneNumberMessage +
          '.',
      );
      return;
    }
    setBusy(true);
    const fullNumber = countryCode.toString() + phoneNumber.toString();
    if (!buttonDisabled) {
      const response: any = await sendPhoneNumber(processWord(fullNumber));
      if (response.status == 201) {
        setBusy(false);
        setRequested(true);
        setButtonDisabled(true);
        localStorage.setItem('phone', countryCode + ' ' + phoneNumber);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 2000);
        return;
      }
      if (response.error) {
        setBusy(false);
        showError(response.error.message ?? response.error.details);
        return;
      }
    }
  };

  const sendCredentialCode = async () => {
    if (code != '' && countryCode && phoneNumber) {
      setBusy(true);
      const fullNumber = countryCode.toString() + phoneNumber.toString();

      const response: any = await sendPhoneCodeRequest(
        processWord(fullNumber),
        code,
      );
      if (response.status == 201) {
        setBusy(false);
        navigate('/phone-success');
        localStorage.removeItem('phone');
      } else {
        setBusy(false);
        showError(response.error.details);
      }
    }
  };

  const showError = (message: string) => {
    setErrorMessage(message);
    setState({...errorState, open: true});
  };

  const hideError = () => {
    setState({...errorState, open: false});
  };

  const handleChange = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 16) {
      setPhoneNumber(onlyNums);
    }
    /*  } else if (onlyNums.length === 9) {
      const number = onlyNums.replace(
        /(\d{3})(\d{2})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
      setPhoneNumber(number);
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      setPhoneNumber(number);
    } */
  };

  const handlePhoneCode = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 7) {
      setCode(onlyNums);
    }
  };

  const handleChangeCountryCode = (e: any) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 3) {
      setCountryCode(onlyNums);
    } else if (onlyNums.length <= 3) {
      const number = onlyNums.replace(/(\d{2})/, '$1');
      setCountryCode(number);
    }
  };

  return (
    <Grid container className="mainContainer">
      <Grid item xs md className="titleContainer">
        <text className="topTitlePhone">{t('phoneProcess.title')}</text>
      </Grid>
      <Grid container className="midConnectContainerBackgroundEmail">
        {busy && (
          <Box sx={{width: '100%'}}>
            <LinearProgress />
          </Box>
        )}
        <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
          <Grid xs={12} md={12}>
            <text className="stepLabel">
              {t('shared.step').toUpperCase()} {requested ? '02' : '01'}
            </text>
            <br />
            <br />
            <text className="leftSideTitle">{t('phoneProcess.titleAlt')}</text>
          </Grid>
          <div className="dividerStep" />
          <Grid xs={12} md={12}>
            {requested ? (
              <Input
                disableUnderline
                sx={{
                  background: '#CFD0DB',
                  borderRadius: '2px',
                  maxWidth: '25rem',
                  width: '100%',
                  p: 1,
                  px: 3,
                  mb: 3,
                }}
                value={code}
                onChange={handlePhoneCode}
                placeholder={t('phoneProcess.placeholderCode')}
                type="text"
              />
            ) : (
              <Grid>
                <Input
                  required={true}
                  disableUnderline
                  value={countryCode}
                  sx={{
                    background: '#CFD0DB',
                    borderRadius: '2px',
                    maxWidth: '70px',
                    width: '20%',
                    p: 1,
                    mb: 3,
                  }}
                  onChange={handleChangeCountryCode}
                  placeholder={'34'}
                  type={'numeric'}
                  startAdornment={
                    <InputAdornment position="start">+</InputAdornment>
                  }
                />
                <Input
                  required={true}
                  disableUnderline
                  value={phoneNumber}
                  sx={{
                    background: '#CFD0DB',
                    borderRadius: '2px',
                    maxWidth: '25rem',
                    width: 'calc(80% - 10px)',
                    p: 1,
                    px: 3,
                    mb: 3,
                    ml: '10px',
                  }}
                  onChange={handleChange}
                  placeholder={t('phoneProcess.placeholderPhone')}
                  type={'numeric'}
                />
              </Grid>
            )}
            <br />
            <text className="leftContent">
              {t(`phoneProcess.processDescription${requested ? 2 : 1}`)}
            </text>
          </Grid>
          <Grid container sx={{alignItems: 'center'}} xs={12} md={12}>
            <Grid xs={12} md={8}>
              <Box
                sx={{
                  border: 2,
                  borderRadius: '20px',
                  p: 1.5,
                  px: 3,
                  my: 7,
                  cursor: 'pointer',
                }}
                onClick={requested ? sendCredentialCode : sendPhoneCode}
                display={'flex'}
                justifyContent={'space-between'}>
                <text className="buttonLabel">
                  {t(`shared.${requested ? 'validate' : 'sendCode'}`)}
                </text>
                <img
                  className="buttonGo"
                  src={require('../../assets/images/arrow-right.png')}
                />
              </Box>
            </Grid>
            <Grid sx={{textAlign: 'center'}} xs={12} md={4}>
              {requested && (
                <text
                  onClick={sendPhoneCode}
                  style={{
                    cursor: 'pointer',
                    color: buttonDisabled ? 'lightgrey' : 'black',
                  }}>
                  {t('shared.sendNewCode')}
                </text>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={6}>
          <img
            className="emailIssueImage"
            src={require('../../assets/images/phoneIssue.png')}
          />
        </Grid>
      </Grid>
      <Grid container className="bottomContainer">
        {' '}
        <Snackbar
          key={vertical + horizontal}
          anchorOrigin={{vertical, horizontal}}
          open={open}
          autoHideDuration={3000}
          onClose={hideError}>
          <Alert onClose={hideError} severity="error" sx={{width: '100%'}}>
            <text style={{fontSize: 18, fontWeight: 'bold'}}>
              {errorMessage}
            </text>
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}

export default EmailForm;
