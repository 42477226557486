import {Box, Grid} from '@mui/material';
import React from 'react';
import './Styles.css';
import {useTranslation} from 'react-i18next';

const services = ['vidconnect', 'vidcredential', 'vidverifier', 'vidwallet'];

function OtherServices() {
  const {t} = useTranslation();
  return (
    <Grid container className="otherServicesMainContainer">
      {services.map(translationKey => {
        return (
          <Grid xs={12} md={5.7} className="serviceItemContainer">
            <text className="serviceItemTitle">
              {t(`home.otherServices.${translationKey}.title`)}
            </text>
            <br />
            <br />
            <br />
            <text className="serviceItemDescription">
              {t(`home.otherServices.${translationKey}.description`)}
            </text>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default OtherServices;
