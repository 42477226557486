import './Styles.css';
import React from 'react';
import {Grid, Popover, Typography} from '@mui/material';
import {OpenIDClient} from '../libs/client';
import {Link, useNavigate} from 'react-router-dom';
import {logOut} from '../services/vidcredentials';
import {useTranslation} from 'react-i18next';
import LanguageSelector from './LanguageSelector/LanguageSelector';

interface HeaderProps {
  handleClick: (refNum: number) => void;
}

function Header({handleClick}: HeaderProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const currentCode = localStorage.getItem('code');
  const open = Boolean(anchorEl);
  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getProvider();
    client.wipeTokens();
    await client.callback();
    await client.getToken({
      scopes: {
        request: ['openid'],
      },
    });
  };

  const doLogOut = async () => {
    logOut();
    navigate('/');
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="headerContainer">
      <Link to={'/'} className="headerLogo">
        <img src={require('../assets/images/logoVIDchain.png')} height={50} />
      </Link>
      <div className="headerWide">
        <div>
          <text className="headerLink" onClick={() => handleClick(1)}>
            {t('home.sections.what')}
          </text>
        </div>
        <div>
          <text className="headerLink" onClick={() => handleClick(2)}>
            {t('home.sections.issue')}
          </text>
        </div>
        <div>
          <text className="headerLink" onClick={() => handleClick(3)}>
            {t('home.sections.other')}
          </text>
        </div>
        <div>
          <text
            className="headerLink"
            onClick={e => {
              window.location.href = 'mailto:support@vidchain.org';
              e.preventDefault();
            }}>
            {t('home.sections.contact')}
          </text>
        </div>
        <LanguageSelector />
        <div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>
            <Typography sx={{p: 1}}>
              {currentCode ? 'Logout' : 'Login'}
            </Typography>
          </Popover>
          {currentCode ? (
            <img
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={doLogOut}
              className="loggedIcon"
              src={require('../assets/images/logged.png')}
            />
          ) : (
            <img
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={signInWithVIDchain}
              className="loggedIcon"
              src={require('../assets/images/notlogged.png')}
            />
          )}
        </div>
      </div>
      <div className="headerMobile">
        {currentCode ? (
          <img
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={doLogOut}
            className="loggedIcon"
            src={require('../assets/images/logged.png')}
          />
        ) : (
          <img
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={signInWithVIDchain}
            className="loggedIcon"
            src={require('../assets/images/notlogged.png')}
          />
        )}
        <div>
          <input id="menu__toggle" type="checkbox" />
          <label className="menu__btn" htmlFor="menu__toggle">
            <span></span>
          </label>
          <ul className="menu__box">
            <li>
              <text
                className="headerLink menu__item"
                onClick={() => handleClick(1)}>
                {t('home.sections.what')}
              </text>
            </li>
            <li>
              <text
                className="headerLink menu__item"
                onClick={() => handleClick(2)}>
                {t('home.sections.issue')}
              </text>
            </li>
            <li>
              <text
                className="headerLink menu__item"
                onClick={() => handleClick(3)}>
                {t('home.sections.other')}
              </text>
            </li>
            <li>
              <a
                className="menu__item"
                onClick={e => {
                  window.location.href = 'mailto:support@vidchain.org';
                  e.preventDefault();
                }}>
                {t('home.sections.contact')}
              </a>
            </li>
            <li>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
