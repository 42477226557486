export default {
  shared: {
    getStarted: 'Empezar',
    step: 'Paso',
    connect: 'Conectar',
    validate: 'Validar',
    sendCode: 'Enviar código',
    sendNewCode: 'Enviar código nuevo',
  },
  home: {
    getCredentials: 'Obtén tus credenciales verificables en tu wallet',
    sections: {
      what: 'Qué es',
      issue: 'Emitir credenciales',
      other: 'Otros servicios',
      contact: 'Contacto',
    },
    firstSection: {
      title: '¿Qué es VIDissuer?',
      issuerDefinitionBold:
        'VIDissuer es un servicio proporcionado por Validated ID',
      issuerDefinition:
        'para emitir credenciales verificables directamente a tu billetera de identidad de custodia propia.',
      secondParagraph:
        'El wallet de identidad de Validated ID, VIDwallet, disponible en iOS y Android, implementa una contraparte digital de una billetera física que permite a los usuarios almacenar y administrar fácilmente sus credenciales digitales.',
      thirdParagraph:
        'Cada credencial digital, también conocida como credencial verificable, es similar a una tarjeta que compartes desde tu wallet de identidad. Estas tarjetas pueden incluir una variedad de información de identidad verificada, como tu número de teléfono, dirección de correo electrónico, número de DNI o pasaporte, o cuentas de redes sociales.',
    },
    secondSection: {
      title: 'Emitir credenciales nunca ha sido tan fácil',
      subtitle:
        'Para crear una credencial verificable, simplemente elije la que necesitas de las opciones que proporcionamos a continuación. Luego haz clic en el enlace y sigue las instrucciones correspondientes. Aquí hay una lista de opciones que te permiten crear y emitir credenciales verificables:',
      comingSoon: 'Próximamente',
    },
    credentialTypes: {
      phone: {
        title: 'Número de teléfono móvil',
        description:
          'Verifica tu número de contacto solicitando que se te envíe un código de verificación a tu dispositivo, y conviértelo en una credencial verificada en tu VIDwallet.',
      },
      email: {
        title: 'Correo electrónico',
        description:
          'Verifica tu correo electrónico solicitando que se envíe un código de verificación a tu correo y guárdalo como una credencial verificada en tu VIDwallet.',
      },
      id: {
        title: 'Documento de identidad o pasaporte',
        description:
          'Verifica tu identidad en segundos mediante procesos KYC de confianza y almacena los valores relacionados con su identidad en forma de credenciales en tu VIDwallet.',
      },
      socialNetworks: {
        title: 'Redes Sociales',
        description:
          'Nuestras soluciones basadas en SSI te ayudan a elegir qué plataformas de redes sociales pueden acceder a tu información personal y te proporcionan el control para recuperarla en cualquier momento.',
      },
      certBridge: {
        title: 'CertBridge',
        description:
          'Sella credenciales verificables mediante certificados cualificados y haz que las soluciones SSI sean escalables e interoperables. Ofrecemos más seguridad a los ciudadanos mejorando la seguridad jurídica de cualquier credencial verificable.',
      },
    },
    otherServices: {
      vidconnect: {
        title: 'VIDconnect',
        description:
          'Implementar protocolos de identidad a través de la familia “OpenID Connect para credenciales verificables”, y conecta a los usuarios de VIDchain con otros servicios.',
      },
      vidcredential: {
        title: 'VIDcredential',
        description:
          'Proporciona una protección excepcional de administración de credenciales e identidad digital para todas las identidades en todos los dispositivos con VIDcredentials Studio.',
      },
      vidverifier: {
        title: 'VIDverifier',
        description:
          'Verifica la autenticidad y validez de las credenciales simplemente realizando un flujo de autenticación hacia VIDverifier.',
      },
      vidwallet: {
        title: 'VIDwallet',
        description:
          'Recupera el control de tu identidad digital, almacena de forma segura tus credenciales verificables y compártelas fácilmente con otras personas cuando sea necesario.',
      },
    },
  },
  connectWallet: {
    title: 'Conectar el wallet',
    titleAlt: 'Conecta tu wallet',
    description:
      'Si te encuentras en esta pantalla, es posible que tu monedero aún no esté conectado, esto sirve para que podamos reconocer tu identidad y puedas descargar credenciales de forma más rápida y segura.',
    instructions:
      'Es muy fácil, sólo tienes que abrir la cámara de tu dispositivo y escanear el código QR.',
  },
  phoneProcess: {
    title: 'Credencial de teléfono móvil',
    titleAlt: 'Teléfono móvil',
    getBadge: 'Consigue tu tarjeta en dos sencillos pasos',
    description:
      'Verifica tu número de teléfono solicitando el envío de un código de verificación a tu dispositivo y guarda la credencial verificable en tu VIDwallet.',
    step1: 'Introduce tu número de teléfono.',
    step2: 'Recibirás un código de verificación en tu teléfono.',
    placeholderPhone: 'Escribe tu número de teléfono.',
    placeholderCode: 'Escribe tu código de verificación.',
    processDescription1: 'Recibirás un código de verificación en tu teléfono',
    processDescription2:
      'Te hemos enviado un SMS. Por favor, comprueba tu buzón de mensajes.',
  },
  emailProcess: {
    title: 'Credencial de correo electrónico',
    titleAlt: 'Verificación de correo electrónico',
    description:
      'Verifica tu correo electrónico solicitando el envío de un código de verificación a tu bandeja de entrada y guarda la credencial verificable en tu VIDwallet.',
    step1: 'Introduce tu dirección de correo electrónico',
    step2: 'Recibirás un código de verificación en tu correo electrónico.',
    processDescription1: 'Recibirás un código de verificación en tu correo',
    processDescription2:
      'Te hemos enviado un correo. Por favor, comprueba tu bandeja de entrada.',
    invalidEmail: `Por favor, introduce una dirección de email válida.`
  },
  kycProcess:{
    title: 'Credencial de documento de identidad',
    titleAlt: 'Número de identificación',
    getBadge: 'Consigue tu tarjeta en dos sencillos pasos',
    titleAltOK: '¡Bien hecho!',
    titleAltProcessing: 'Verificación en proceso...',
    titleAltKO: '¡El proceso ha fallado!',
    description:'Verifica tu identidad en segundos mediante procesos KYC de confianza y almacena los valores relacionados con su identidad en forma de credenciales en tu VIDwallet.',
    step1:'Serás redirigido a nuestra pantalla de validación',
    step2:'Obtén tu credencial',
    processDescription1:'',
    processDescription2:'',
    results:{
      success:{
        subtitle: '¡Bien hecho!',
        messageBold: '¡El proceso se ha completado satisfactoriamente!',
        message: 'Tu credencial ha sido generada.',
      },
      processing:{
        subtitle: 'Verificación en proceso...',
        messageBold: 'Los datos del documento están siendo procesados.',
        message: 'Puede tardar unos segundos, por favor espere.',
      },
      fail:{
        subtitle: '¡El proceso ha fallado!',
        messageBold: 'Los datos no pudieron ser procesados. Asegúrate de que tu documento sea válido y que las imágenes sean claras y legibles. Inténtalo de nuevo cuando estés listo.',
        message: 'Por favor, vuelve a intentarlo.',
      }
    },
    button:{
      startProcess: 'Comenzar validación de identidad',
      retryProcess: 'Repetir el proceso',
      getVerification: 'Obtener credencial'
    },
    iframeText:{
      title:'Este código QR debe escanearse desde la cámara de su teléfono móvil, NO desde el Wallet.',
      subtitle:'Debe continuar el proceso con su teléfono móvil',
      message:'No cierre esta ventana. El proceso se reanudará cuando finalice la validación.'
    }
  },
  kycInvitationCode:{
    title: 'Código de invitación',
    description: "Esta credencial requiere un código de invitación, si no tiene uno, comuníquese con Validated ID.",
    button:{
      continue: 'Continuar',
      retry: 'Volver a intentar',
    },
    incorrectMessage: 'Código incorrecto, inténtalo de nuevo.'
  },
  success: {
    subtitle: '¡Bien hecho!',
    messageBold: '¡El proceso se ha completado satisfactoriamente!',
    message:
      'Dirígete a la wallet y acepta la credencial.',
  },
};
