import {Alert, Container, Grid} from '@mui/material';
import React from 'react';
import './Styles.css';
import {OpenIDClient} from '../../libs/client';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

function KycCredential() {
  const {t} = useTranslation();
  const currentCode = localStorage.getItem('code');
  const navigate = useNavigate();
  const issueCertCredential = async () => {
    if (currentCode) {
      navigate('/kyc-process');
    } else {
      localStorage.setItem('path', '/kyc-process');
      navigate('/issue-credential/connect-wallet');
    }
  };

  return (
    <Grid container className="mainContainer">
      <Grid item xs md className="titleContainer">
        <text className="topTitleCert">{t('kycProcess.title')}</text>
      </Grid>
      <Grid container className="midContainerCertBackground">
        <Grid xs={12} md={8}>
          <img
            className="imageEmail"
            src={require('../../assets/images/imageKycIssue.png')}
          />
        </Grid>
        <Grid xs={6} md={2}>
          <text className="labelStart">{t('shared.getStarted')}</text>
        </Grid>
        <Grid xs={6} md={2}>
          <img
            onClick={issueCertCredential}
            className="buttonForward"
            src={require('../../assets/images/alphaButtonGo.png')}
          />
        </Grid>
      </Grid>
      <Grid container className="bottomContainer">
        <Grid xs={12} md={6}>
          <text className="descriptionEmailTitle">
            {t('kycProcess.getBadge')}
          </text>
        </Grid>
        <Grid xs={12} md={6}>
          <text className="descriptionEmail">
            {t('kycProcess.description')}
            <br />
            <br />
            <b>{t('shared.step')} 01.</b> {t('kycProcess.step1')}
            <br />
            <br />
            <b> {t('shared.step')} 02.</b> {t('kycProcess.step2')}
          </text>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default KycCredential;
