import Success from '../../components/Success/Success';
import {useNavigate} from 'react-router-dom';
import HeaderWrapper from '../../providers/HeaderWrapper';

interface SuccessPageProps {
  type: string;
}

function SuccessPage({type}: SuccessPageProps) {
  const navigate = useNavigate();

  return (
    <HeaderWrapper
      handleClick={(refNum: number) =>
        navigate('/', {state: {section: refNum}})
      }>
      <Success type={type} />
    </HeaderWrapper>
  );
}

export default SuccessPage;
