import {Container, Grid} from '@mui/material';
import React from 'react';
import './Styles.css';
import {useTranslation} from 'react-i18next';

function WhatIsItSection() {
  const {t} = useTranslation();
  return (
    <Grid container className="whatIsItcontainer">
      <Grid xs={12} md={6}>
        <text className="whatIsItTitle">{t('home.firstSection.title')}</text>
      </Grid>
      <Grid xs={12} md={6}>
        <text>
          <p className="paragraphText">
            <b>{t('home.firstSection.issuerDefinitionBold')}</b>{' '}
            {t('home.firstSection.issuerDefinition')}
          </p>
          <p className="paragraphText">
            {t('home.firstSection.secondParagraph')}
          </p>
          <p className="paragraphText">
            {t('home.firstSection.thirdParagraph')}
          </p>
        </text>
      </Grid>
    </Grid>
  );
}

export default WhatIsItSection;
