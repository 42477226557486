import React, {PropsWithChildren} from 'react';
import Header from '../components/Header';

interface HeaderProviderProps {
  handleClick: (refNum: number) => void;
}
function HeaderWrapper({
  children,
  handleClick,
}: PropsWithChildren<HeaderProviderProps>) {
  return (
    <div className="main">
      <Header handleClick={handleClick} />
      {children}
    </div>
  );
}

export default HeaderWrapper;
