import {Box, Container, Grid} from '@mui/material';
import React from 'react';
import './Styles.css';
import {Link} from 'react-router-dom';
import EmailCredential from './IssueEmail/EmailCredential';
import {useTranslation} from 'react-i18next';

const credentialTypes = [
  {
    translationKey: 'phone',
    destination: '',
    image: require('../assets/images/phoneType.png'),
    to: '/issue-credential/phone',
  },
  {
    translationKey: 'email',
    destination: '',
    image: require('../assets/images/emailType.png'),
    to: '/issue-credential/email',
  },
  {
    translationKey: 'id',
    destination: '',
    image: require('../assets/images/kycType.png'),
    to: '/issue-credential/kyc',
  },
  {
    translationKey: 'socialNetworks',
    destination: '',
    image: require('../assets/images/socialnetworkType.png'),
    to: '/issue-credential/social-network',
  },
  {
    translationKey: 'certBridge',
    destination: '',
    image: require('../assets/images/certbridgeType.png'),
    to: '/issue-credential/cert',
  },
];

function IssueCredential() {
  const {t} = useTranslation();
  return (
    <Grid container className="issueCredentialContainer">
      <text className="issueCredentialMainTitle">
        {t('home.secondSection.title')}
      </text>
      <text className="issueCredentialMainDescription">
        {t('home.secondSection.subtitle')}
      </text>
      {credentialTypes.map(item => {
        {
          return (
            <Grid container className="issueCredentialItemContainer">
              <Grid xs={12} md={6}>
                <Link
                  to={
                    item.to.includes('/phone') || item.to.includes('/email') || item.to.includes('/kyc')
                      ? item.to
                      : '#'
                  }>
                  {!(
                    item.to.includes('/phone') || item.to.includes('/email') || item.to.includes('/kyc')
                  ) && (
                    <div className="cardBackground comingSoonLayer">
                      <text style={{color: 'white'}}>
                        <h1>{t('home.secondSection.comingSoon')}</h1>
                      </text>
                    </div>
                  )}
                  <img className="cardBackground" src={item.image} />
                </Link>
              </Grid>
              <Grid
                xs={12}
                md={6}
                className="typeCredentialRight"
                sx={{
                  opacity:
                    item.to.includes('/phone') || item.to.includes('/email') || item.to.includes('/kyc')
                      ? 1
                      : 0.4,
                }}>
                <Link
                  to={
                    item.to.includes('/phone') || item.to.includes('/email') || item.to.includes('/kyc')
                      ? item.to
                      : '#'
                  }>
                  <text className="issueCredentialTitle">
                    {t(`home.credentialTypes.${item.translationKey}.title`)}
                  </text>
                </Link>
                <img
                  className="iconArrow"
                  src={require('../assets/images/arrowUpWhite.png')}
                />
                <br />
                <br />
                <br />
                <text className="issueCredentialDescription">
                  {t(`home.credentialTypes.${item.translationKey}.description`)}
                </text>
              </Grid>
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

export default IssueCredential;
