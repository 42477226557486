import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import es from './es';
import ca from './ca';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {escapeValue: false},
    supportedLngs: ['en', 'es', 'ca'],
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ca: {
        translation: ca,
      },
    },
  });

const supportedLanguages: Record<string, string> = {
  en: 'English',
  es: 'Español',
  ca: 'Català',
};

export {supportedLanguages};
export default i18n;
