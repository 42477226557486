export default {
  shared: {
    getStarted: 'Començar',
    step: 'Pas',
    connect: 'Connectar',
    validate: 'Validar',
    sendCode: 'Enviar codi',
    sendNewCode: 'Enviar codi nou',
  },
  home: {
    getCredentials: 'Obtén les teves credencials verificables al teu wallet',
    sections: {
      what: 'Què és',
      issue: 'Emetre credencials',
      other: 'Altres serveis',
      contact: 'Contacte',
    },
    firstSection: {
      title: 'Què és VIDissuer?',
      issuerDefinitionBold:
        'VIDissuer és un servei proporcionat per Validated ID',
      issuerDefinition:
        "per emetre credencials verificables directament a la teva bitlletera d'identitat de custòdia pròpia.",
      secondParagraph:
        "El wallet d'identitat de Validated ID, VIDwallet, disponible a iOS i Android, implementa una contrapart digital d'una bitlletera física que permet als usuaris emmagatzemar i administrar fàcilment les seves credencials digitals.",
      thirdParagraph:
        "Cada credencial digital, també coneguda com a credencial verificable, és similar a una targeta que comparteixes des del teu wallet d'identitat. Aquestes targetes poden incloure una varietat d'informació d'identitat verificada, com el teu número de telèfon, adreça de correu electrònic, número de DNI o passaport, o comptes de xarxes socials.",
    },
    secondSection: {
      title: 'Emetre credencials mai no ha estat tan fàcil',
      subtitle:
        "Per a crear una credencial verificable, simplement escull, d'entre les opcions que proporcionem a continuació, la que necessitis. Després fes clic a l'enllaç i segueix les instruccions corresponents. Aquí hi ha una llista d'opcions que et permeten crear i emetre credencials verificables:",
      comingSoon: 'Pròximament',
    },
    credentialTypes: {
      phone: {
        title: 'Número de telèfon mòbil',
        description:
          "Verifica el teu número de contacte sol·licitant que se t'enviï un codi de verificació al teu dispositiu, i converteix-ho en una credencial verificada al teu VIDwallet.",
      },
      email: {
        title: 'Correu electrònic',
        description:
          "Verifica el teu correu electrònic sol·licitant que se t'enviï un codi de verificació al teu correu i desa-ho com una credencial verificada al teu VIDwallet.",
      },
      id: {
        title: "Document d'identitat o passaport",
        description:
          'Verifica la teva identitat en segons mitjançant processos KYC de confiança i emmagatzema els valors relacionats amb la teva identitat en forma de credencials al teu VIDwallet.',
      },
      socialNetworks: {
        title: 'Xarxes Socials',
        description:
          "Les nostres solucions basades en SSI t'ajuden a escollir quines plataformes de xarxes socials poden accedir a la teva informació personal i et proporcionen el control per a recuperar-la en qualsevol moment.",
      },
      certBridge: {
        title: 'CertBridge',
        description:
          'Sella credencials verificables mitjançant certificats qualificats i fes que les solucions SSI siguin escalables i interoperables. Oferim més seguretat als ciutadans millorant la seguretat jurídica de qualsevol credencial verificable.',
      },
    },
    otherServices: {
      vidconnect: {
        title: 'VIDconnect',
        description:
          "Implementar protocols d'identitat a través de la família “OpenID Connect per a credencials verificables”, i connecta als usuaris de VIDchain amb altres serveis.",
      },
      vidcredential: {
        title: 'VIDcredential',
        description:
          "Proporciona una protecció excepcional d'administració de credencials i identitat digital per a totes les identitats en tots els dispositius amb VIDcredentials Studio.",
      },
      vidverifier: {
        title: 'VIDverifier',
        description:
          "Verifica l'autenticitat i validesa de les credencials simplement realitzant un flux d'autenticació cap al VIDverifier.",
      },
      vidwallet: {
        title: 'VIDwallet',
        description:
          'Recupera el control de la teva identitat digital, emmagatzema de forma segura les teves credencials verificables i comparteix-les fàcilment amb altres persones quan sigui necessari.',
      },
    },
  },
  connectWallet: {
    title: 'Connectar el wallet',
    titleAlt: 'Connecta el teu wallet',
    description:
      'Si et trobes en aquesta pantalla, és possible que el teu wallet encara no estigui connectat, això serveix perquè puguem reconèixer la teva identitat i puguis baixar credencials de forma més ràpida i segura.',
    instructions:
      "És molt fàcil, solament has d'obrir la càmera del teu dispositiu i escanejar el codi QR.",
  },
  phoneProcess: {
    title: 'Credencial de telèfon mòbil',
    titleAlt: 'Telèfon mòbil',
    getBadge: 'Aconsegueix la teva targeta en dos senzills passos',
    description:
      "Verifica el teu número de telèfon sol·licitant l'enviament d'un codi de verificació al teu dispositiu i guarda la credencial verificable al teu VIDwallet.",
    step1: 'Introdueix el teu número de telèfon.',
    step2: 'Rebràs un códi de verificació al teu telèfon.',
    placeholderPhone: 'Escriu el teu número de telèfon.',
    placeholderCode: 'Escriu el teu codi de verificació.',
    processDescription1: 'Rebràs un codi de verificació al teu telèfon',
    processDescription2:
      "T'hem enviat un SMS. Si us plau, comprova la teva bústia de missatges.",
  },
  emailProcess: {
    title: 'Credencial de correu electrònic',
    titleAlt: 'Verificació de correu electrònic',
    description:
      "Verifica el teu correu electrònic sol·licitant l'enviament d'un codi de verificació a la teva safata d'entrada i desa la credencial verificable al teu VIDwallet.",
    step1: 'Introdueix la teva direcció de correu electrònic',
    step2: 'Rebràs un códi de verificació al teu correu electrònic.',
    processDescription1: 'Rebràs un códi de verificació al teu correu',
    processDescription2:
      "T'hem enviat un correu. Si us plau, comprova la teva safata d'entrada.",
    invalidEmail: `Si us plau, introdueix una adreça d'email vàlida.`
  },
  kycProcess:{
    title: "Credencial de document d'identitat",
    titleAlt: "Número d' identificació",
    getBadge: 'Aconsegueix la teva targeta en dos senzills passos',
    titleAltOK: '¡Ben fet!',
    titleAltProcessing: 'Verificació en procés...',
    titleAltKO: 'El procés ha fallat!',
    description:'Verifica la teva identitat en segons mitjançant processos KYC de confiança i emmagatzema els valors relacionats amb la teva identitat en forma de credencials al teu VIDwallet.',
    step1:'Seràs redirigit a la nostra pantalla de validació',
    step2:'Obteniu la vostra credencial',
    processDescription1:'',
    processDescription2:'',
    results:{
      success:{
        subtitle: '¡Ben fet!',
        messageBold: "¡El procés s'ha completat satisfactòriament!",
        message: 'La teva credencial ha estat generada.',
      },
      processing:{
        subtitle: 'Verificació en procés...',
        messageBold: 'Les dades del document estan sent processades.',
        message: 'Pot trigar uns segons, per favor espereu.',
      },
      fail:{
        subtitle: 'El procés ha fallat!',
        messageBold: "Les dades no s'han pogut processar. Assegura't que el teu document sigui vàlid i que les imatges siguin clares i llegibles. Intenta-ho de nou quan estiguis llest.",
        message: 'Si us plau, torna a intentar-ho.',
      }
    },
    button:{
      startProcess: "Començar validació d'identitat",
      retryProcess: 'repetir el procés',
      getVerification: 'Obtenir credencial'
    },
    iframeText:{
      title:"Aquest codi QR s'ha d'escanejar des de la càmera del telèfon mòbil, NO des de la Wallet.",
      subtitle:'Has de continuar el procés amb el telèfon mòbil',
      message:'No tanqueu aquesta finestra. El procés es reprendrà quan finalitzi la validació.'
    }
  },
  kycInvitationCode:{
    title: "Codi d'invitació",
    description: "Aquesta credencial requereix un codi d'invitació, si no en teniu un, poseu-vos en contacte amb Validated ID.",
    button:{
      continue: 'Continuar',
      retry: 'Tornar a intentar',
    },
    incorrectMessage: 'Codi incorrecte, intenta-ho de nou.'
  },
  success: {
    subtitle: 'Ben fet!',
    messageBold: "El procés s'ha completat satisfactòriament!",
    message:
      "Dirigeix-te a la wallet i accepta la credencial.",
  },
};
