import {
    Alert,
    Box,
    Grid,
    Input,
    InputAdornment,
    LinearProgress,
    Snackbar,
    SnackbarOrigin,
    IconButton
} from '@mui/material';
import React, {useState} from 'react';
import './Styles.css';
import {
     sendInvitationCodeRequest
} from '../../services/vidcredentials';
import {useTranslation} from "react-i18next";

interface State extends SnackbarOrigin {
    open: boolean;
}

interface KycInvitationFormProps {
    onValidate: (isValid: boolean) => void;
}

const KycInvitationForm: React.FC<KycInvitationFormProps> = ({ onValidate }) => {
    const {t} = useTranslation();
    const [errorState, setState] = React.useState<State>({
        open: false,
        vertical: 'bottom',
        horizontal: 'center',
    });
    const [busy, setBusy] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const {vertical, horizontal, open} = errorState;
    const [invitationCode, setInvitationCode] = useState<string>('');
    const [isWrongInvitationCode, setIsWrongInvitationCode] = useState<boolean>(false);
    const [showInvitationCode, setShowInvitationCode] = useState<boolean>(false);

    const isValidInvitationCode = async (code:string) => {
        const response: any = await sendInvitationCodeRequest(code);
        if(!response || response.status !== 201 || !response.data){
            return false;
        }
        return response.data.allowed;
    }

    const handleValidation = async () => {
        const isValid = await isValidInvitationCode(invitationCode);
        setIsWrongInvitationCode(!isValid);
        onValidate(isValid);
    };

    const showError = (message: string) => {
        setErrorMessage(message);
        setState({...errorState, open: true});
    };

    const hideError = () => {
        setState({...errorState, open: false});
    };

    const toggleShowPassword = ()=>{
        setShowInvitationCode(!showInvitationCode);
    }

    return (
        <Grid container className="mainContainer">
            <Grid item xs md className="titleContainer">
                <text className="topTitleCert">{t('kycInvitationCode.title')}</text>
            </Grid>
            <Grid container className="midConnectContainerBackgroundEmail">
                {busy && (
                    <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>
                )}
                <Grid xs={12} md={6} sx={{paddingRight: '5%'}}>
                    <Grid xs={12} md={12}>
                        <br/><br/>
                        <text className="leftContent">
                            {t('kycInvitationCode.description')}
                        </text>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    <Grid xs={12} md={12}>
                        <Input
                            disableUnderline={true}
                            value={invitationCode}
                            sx={{
                                background: '#CFD0DB',
                                borderRadius: '2px',
                                maxWidth: '35rem',
                                width: '100%',
                                p: 1,
                                px: 3,
                                mb: 3,
                                border: isWrongInvitationCode ? '2px solid #eda9ad' : 'none',
                            }}
                            onChange={(e) => setInvitationCode(e.target.value)}
                            type={ showInvitationCode ? 'text' : 'password' }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                        edge="end"
                                    >
                                        {showInvitationCode ?
                                            <img
                                                className="buttonGo"
                                                src={require('../../assets/images/showInvitationCode.png')}
                                            /> :
                                            <img
                                                className="buttonGo"
                                                src={require('../../assets/images/hideInvitationCode.png')}
                                            />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        { isWrongInvitationCode && (
                            <text className="wrongInvitationCode">{t('kycInvitationCode.incorrectMessage')}</text>
                            )
                        }

                    </Grid>
                    <Grid container sx={{alignItems: 'center'}} xs={12} md={12}>

                        <Grid xs={12} md={8}>
                            <br/>
                            <br/>
                            <Box className="buttonBox"
                                sx={{
                                    border: 2,
                                    borderRadius: '20px',
                                    p: 1.5,
                                    px: 3,
                                    my: 7,
                                    cursor: 'pointer',
                                }}
                                onClick={handleValidation}
                                display={'flex'}
                                justifyContent={'space-between'}>
                                <text className="buttonLabel">
                                    {isWrongInvitationCode ? t('kycInvitationCode.button.retry') : t('kycInvitationCode.button.continue')}
                                </text>
                                <img
                                    className="buttonGo"
                                    src={require('../../assets/images/arrow-right.png')}
                                />
                            </Box>

                        </Grid>

                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    <img
                        className="invitationCodeImage"
                        src={require('../../assets/images/kycInvitation.png')}
                    />
                </Grid>
            </Grid>
            <Grid container className="bottomContainer">
                {' '}
                <Snackbar
                    key={vertical + horizontal}
                    anchorOrigin={{vertical, horizontal}}
                    open={open}
                    autoHideDuration={3000}
                    onClose={hideError}>
                    <Alert onClose={hideError} severity="error" sx={{width: '100%'}}>
                        <text style={{fontSize: 18, fontWeight: 'bold'}}>
                            {errorMessage}
                        </text>
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}

export default KycInvitationForm;
